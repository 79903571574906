import React, { useState, useContext } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { 
  Navbar, 
  NavbarItem, 
  NavbarContent, 
  NavbarMenuToggle, 
  NavbarMenu } from '@heroui/react'

const Menu = dynamic(() => import('./menu'), { ssr: false });
import ChallengeImage from './challenge-image'
import { UserContext } from '../contexts/user-context'
import useChallengeStatus from '../hooks/use-challenge-status'
import { formatMetric, displayMeasurement, formatDate } from '../src/formatter'

const Header = ({ challenge, section, page }) => {
  const userHook = useContext(UserContext)
  const user = userHook.user

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const challengeStatusHook = useChallengeStatus(user.token, challenge?.id)
  const challengeStatus = challengeStatusHook.challenge

  const router = useRouter()
  
  return <Navbar 
    isMenuOpen={isMenuOpen} 
    onMenuOpenChange={setIsMenuOpen} 
    maxWidth='full'
    isBlurred={false}
    classNames={{ wrapper: `px-0 md:px-0 ${!challenge ? 'h-0' : ''}` }}
    >
    
    {challenge && <NavbarContent justify="start" className={`w-full md:ml-[300px] md:max-w-[calc(100vw-300px)] gap-3 bg-chTan-100`}>
      <NavbarMenuToggle className='ml-2 md:ml-4 md:hidden' aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
      {challenge && <NavbarItem className='min-w-[48px] min-h-[48px] rounded-full overflow-hidden relative md:ml-4'>
        <ChallengeImage name={challenge.challengeName} image={challenge.logo} />
      </NavbarItem>}
      <NavbarItem className='grow mr-4 md:mr-0 h-[50px] mt-2 truncate'>
        <div className='text-lg md:text-2xl truncate cursor-pointer' onClick={() => {
          if (page === 'overview') {
            router.push(`/challenge/${challenge.id}`)
          } else {
            router.push(`/challenge/${challenge.id}/overview`) 
          }
        }}>{challenge?.challengeName}</div>
        {challengeStatus && <span className='text-lg text-chTan-600'>
          {challengeStatus?.over && `Ended on ${formatDate(challengeStatus.end)}`}
          {(!challengeStatus?.over && challengeStatus.daysLeft !== undefined) && `${challengeStatus?.daysLeft} days to go`}
        </span>}
        {page === 'overview' ?
          <Link href='/challenge/[id]/overview' as={`/challenge/${challenge.id}`} className='ml-4 text-primary'>Challenge Home</Link>
          :
          <Link href='/challenge/[id]/overview' as={`/challenge/${challenge.id}/overview`} className='ml-4 text-primary'>See Details</Link>
        }
      </NavbarItem>
      <NavbarItem className='hidden md:block'>
      {(challengeStatus && challengeStatus?.id) && 
        <div className='bg-chTan-100 shadow-md text-center py-2 px-4 leading-tight flex gap-8 cursor-pointer' onClick={() => {
          if (page === 'overview') {
            router.push(`/challenge/${challenge.id}`)
          } else {
            router.push(`/challenge/${challenge.id}/overview`) 
          }
        }}>
          <div>
            <div className='text-xl text-primary'>{formatMetric(challengeStatus.userResults?.distance, 2, 'lg', user.imperial)}</div>
            <div className='text-sm text-chTan-600'>{displayMeasurement(user.imperial)}</div>
          </div>
          <div className='hidden md:block'>
            <div className='text-xl text-primary'>{formatMetric(challengeStatus.userResults?.remaining, 2, 'lg', user.imperial)}</div>
            <div className='text-sm text-chTan-600'>{displayMeasurement(user.imperial)} to go</div>
          </div>
          
        </div>}
      </NavbarItem>
    </NavbarContent>}

    <div className='fixed top-0 left-0 w-[300px] h-screen bg-chTan-300 hidden md:block'>
      <Menu challengeId={challenge?.id} section={section} page={page} />
    </div>
    
    <NavbarMenu className='bg-chTan-300 block md:hidden p-0' >
      <Menu setIsMenuOpen={setIsMenuOpen} challengeId={challenge?.id} section={section} page={page} />
    </NavbarMenu>
  </Navbar>

}

export default Header
