import useSWR from 'swr'
import { jsonGetFetcher } from '../src/fetchers'

function useChallengeStatus (token, challengeId) {
  let url = `${process.env.API}/challenges/${challengeId}/status`
  
  const defaultHook = {
    loading: false,
    challenge: {},
    error: false
  }

  let { data, error } = useSWR(challengeId ? [url, token] : null, jsonGetFetcher, {
    dedupingInterval: 60000,
    focusThrottleInterval: 60000
  })

  if (!data && !error) return { ...defaultHook, loading: true }

  if (error) {
    if (error.status === 401) {
      // user hook will handle logout, just return empty data
      return { ...defaultHook, loading: false }
    }
    console.error(error)
    return { ...defaultHook, error: true, loading: false }
  }

  return { ...defaultHook, loading: false, challenge: data }
}

export default useChallengeStatus
