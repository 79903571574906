import React, { useState } from 'react'
import Image from 'next/image'

const ChallengeImage = ({ image, name }) => {
  const [error, setError] = useState(false)
  const randomNum = Math.floor(Math.random() * 2) + 1;
  return <Image
    width={0} height={0}
    alt={name}
    fill={true}
    src={(image && !error) ? `${process.env.CDN}/${image}` : `/challenge-placeholders/placeholder-${randomNum}.jpg`}
    sizes="(min-width: 768px) 100px, 100px"
    className='rounded-md mx-auto w-auto object-cover object-center'
    onError={() => setError(true)}
  />
}

export default ChallengeImage
